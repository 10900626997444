import React from 'react';
import { App } from './App.js';
import { Login } from './Login.js';
import { Route, Routes } from 'react-router-dom';

export const Home = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<App />}></Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </div>
  );
};
