export function getCookieValue(a) {
  const b = document.cookie.match(`(^|[^;]+)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

export function setCookie(name, value, hours) {
  let expires = '';
  if (hours) {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires};path=/;domain=${
    window.location.hostname
  }`;
}

export function deleteCookie(name) {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${window.location.hostname}`;
}
