import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';
import { setCookie } from './utils';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const [errorMessages, setErrorMessages] = useState({});
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const errors = {
    uname: 'invalid username',
    pass: 'invalid password',
  };

  async function login() {
    try {
      return await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/login`,
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePwd = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login().then((result) => {
      if (result && result.data && result.data.response) {
        setCookie('accessToken', result.data.response, 24);
        navigate('/');
      } else {
        setErrorMessages({ name: 'pass', message: 'Login Failed' });
      }
    });
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input
            type="text"
            name="uname"
            required
            onChange={handleChangeUsername}
          />
          {renderErrorMessage('uname')}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input
            type="password"
            name="pass"
            required
            onChange={handleChangePwd}
          />
          {renderErrorMessage('pass')}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title">Sign In</div>
        {renderForm}
      </div>
    </div>
  );
};
