import axios from 'axios';
import { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import './App.css';
import { getCookieValue } from './utils';
import { useNavigate } from 'react-router-dom';

async function getFalconDashboard() {
  const accessToken = getCookieValue('accessToken');
  return await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/get-dashboard`,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
}
const rota = [{ from: '', to: '', name: '' }];

const round = (v) => {
  return Math.round(v * 100) / 100;
};
const options = [
  { key: 'last5minutes', value: 'Last 5 Minutes' },
  { key: 'last10minutes', value: 'Last 10 Minutes' },
  { key: 'last15minutes', value: 'Last 15 Minutes' },
  { key: 'last30minutes', value: 'Last 30 Minutes' },
  { key: 'last1Hour', value: 'Last 1 Hour' },
  { key: 'last6Hours', value: 'Last 6 Hours' },
  // { key: "last12Hours", value: "Last 12 Hours" },
  // { key: "last24Hours", value: "Last 24 Hours" },
];

export const App = () => {
  const [falconDashboard, setFalconDashboard] = useState([]);
  const [errorThresholdCPU, setErrorThresholdCPU] = useState({});
  const [cpuStats, setCPUStats] = useState([]);
  const navigate = useNavigate();

  const [selectedRangeType, setSelectedRangeType] = useState('last10minutes');
  const handleRefresh = () => {
    const accessToken = getCookieValue('accessToken');
    if (!accessToken) {
      navigate('/login');
    }
    getFalconDashboard()
      .then((result) => {
        if (result.data && result.data && result.data.cpuStats?.data) {
          console.log(result.data, 'presult.data');
          setCPUStats(result.data.cpuStats?.data || []);
          setFalconDashboard(result.data.primaryStats);
        }
      })
      .catch((e) => {
        // deleteCookie('accessToken');
        // navigate('/login');
      });
  };
  useEffect(() => {
    setInterval(() => {
      handleRefresh();
    }, 30000);
    handleRefresh();
  }, [selectedRangeType]);

  // console.log(falconDashboard);

  return (
    <div className="App">
      <div class="item header" style={{ height: 45 }}>
        <h1>Falcon Blaze</h1>
      </div>
      <div
        className="text-right"
        style={{ padding: 4, position: 'absolute', top: 6, right: 4 }}
      >
        <span style={{ color: 'white' }}>Showing </span>
        <select
          value={selectedRangeType}
          onChange={(e) => {
            setSelectedRangeType(e.target.value);
          }}
        >
          {options.map((opt) => (
            <option value={opt.key}>{opt.value}</option>
          ))}
        </select>
      </div>
      <div class="container">
        <div className="row">
          <div className={'tile wide new-offering'}>
            <div
              className="header head"
              style={{ background: 'white', padding: 10, marginBottom: 10 }}
            >
              <div style={{ fontWeight: 'bold' }}>Database CPU utilization</div>

              <div style={{ paddingLeft: 30, fontWeight: 'bold' }}>
                ( CPU Legend: &#60; 50 = Normal , 50 - 65 = High , &#62; 65 =
                Critical )
              </div>

              <div style={{ paddingLeft: 30, fontWeight: 'bold' }}>
                ( Memory Legend: &#60; 50 = Normal , 50 - 65 = High , &#62; 65 =
                Critical )
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: isBrowser ? '' : 'wrap',
              }}
            >
              {(cpuStats || []).map((cpuObj) => {
                if (
                  cpuObj?.name &&
                  !cpuObj.name.includes('in') &&
                  (cpuObj.name.includes('mongo') ||
                    cpuObj.name.includes('mysql') ||
                    cpuObj.name.includes('cb'))
                ) {
                  const cpuUsage = round(cpuObj.cpuMax * 100);
                  const memoryUsage = round(cpuObj.memoryMax * 100);
                  const memoryUsageLimitLow = cpuObj.name.includes(
                    'oms-production-mysql-2'
                  )
                    ? 75
                    : 50;
                  const memoryUsageLimitHigh = cpuObj.name.includes(
                    'oms-production-mysql-2'
                  )
                    ? 85
                    : 65;
                  const dbColor =
                    memoryUsage >= memoryUsageLimitLow &&
                    memoryUsage <= memoryUsageLimitHigh
                      ? 'orange'
                      : 'red';
                  return (
                    <div
                      style={{
                        border: '1px solid #85bfdb',
                        borderRadius: '5px',
                        margin: 5,
                        padding: isBrowser ? '10px 10px' : '10px 10px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: isBrowser ? '10%' : '20%',
                        alignItems: 'center',
                        textAlign: 'center',
                        background:
                          memoryUsage < memoryUsageLimitLow
                            ? '#609931'
                            : dbColor,
                      }}
                    >
                      <div>
                        <div
                          style={{
                            color: 'white',
                            textTransform: 'uppercase',
                          }}
                        >
                          {cpuObj.name.split('-').join(' ')}
                        </div>

                        <div
                          style={{
                            color: 'white',
                            marginTop: 10,
                            color: `${
                              cpuUsage > errorThresholdCPU[cpuObj.name]
                                ? 'red'
                                : 'white'
                            }`,
                          }}
                        >
                          <span className="">CPU:</span> {cpuUsage}%
                        </div>
                        <div
                          style={{
                            color: 'white',
                            marginTop: 0,
                            color: `${
                              memoryUsage > errorThresholdCPU[cpuObj.name]
                                ? 'red'
                                : 'white'
                            }`,
                          }}
                        >
                          MEMORY: {memoryUsage}%
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <hr style={{ margin: '20px', position: 'absolute' }} />
          </div>
        </div>
        <div className="row">
          <div class={`tile wide `}>
            <div class="header head">
              <div class="left title"> Service</div>
              <div class="left" style={{ width: 100 }}>
                <div class="">Errors</div>

                {/* <div class="title">Errors</div> */}
              </div>
              <div class="right" style={{ width: 120 }}>
                <div class="">Avg Latency</div>

                {/* <div class="title">Latency</div> */}
              </div>
              <div className="right" style={{ width: 80 }}>
                RPM
              </div>
              <div className="right" style={{ width: 80 }}>
                CPU
              </div>
              <div className="right" style={{ width: 80 }}>
                Mem
              </div>
              <div className="right" style={{ width: 60 }}>
                Pods
              </div>
            </div>
            {/* <div class="body">
                  <div class="title">
                    
                    &nbsp; &nbsp; &nbsp;
                    
                  </div>
                </div> */}
          </div>
        </div>
        {falconDashboard.map((service) => {
          const {
            cpuClassName = '',
            memoryClassName = '',
            rpmClassName = '',
            className = '',
            errorClassName = '',
            podsClassName = '',
            podsMessage = '',
          } = service?.metrics?.[selectedRangeType] || {};
          return (
            <div className="row">
              <div
                class={`tile wide ${className} ${errorClassName} ${memoryClassName} ${cpuClassName} ${rpmClassName} ${podsClassName}`}
              >
                <div class="header" style={{ backgroundColor: '#333' }}>
                  <div class="left title">
                    {' '}
                    <div>{service?.info?.title}</div>
                  </div>
                  <div
                    class={`left errorCol ${errorClassName}`}
                    style={{
                      width: 100,
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <div class="">
                      <div>
                        {service?.metrics?.[selectedRangeType]?.totalErrors}
                      </div>
                    </div>

                    {/* <div class="title">Errors</div> */}
                  </div>
                  <div
                    className={`right latencyCol ${className}`}
                    style={{
                      width: 120,
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <div class="">
                      <div>
                        {(
                          service?.metrics?.[selectedRangeType]?.latency / 1000
                        ).toFixed(0)}
                      </div>
                    </div>

                    {/* <div class="title">Latency</div> */}
                  </div>
                  <div
                    className={`right rpmCol ${rpmClassName}`}
                    style={{
                      width: 80,
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <span class="info">
                      {(
                        service?.metrics?.[selectedRangeType]?.throughput || 0
                      ).toFixed(0)}
                    </span>
                  </div>
                  <div
                    className={`right cpuCol ${cpuClassName}`}
                    style={{
                      width: 80,
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <span class="info">
                      {(
                        service?.metrics?.[selectedRangeType]?.cpuUsage || 0
                      ).toFixed(2)}
                      %
                    </span>
                  </div>
                  <div
                    className={`right memCol ${memoryClassName}`}
                    style={{
                      width: 80,
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <span class="info">
                      {(
                        service?.metrics?.[selectedRangeType]?.memoryUsage || 0
                      ).toFixed(2)}
                      %
                    </span>
                  </div>
                  <div
                    className={`right podCol ${podsClassName}`}
                    style={{
                      width: 60,
                      textAlign: 'center',
                      color: 'black',
                      fontSize: 'medium',
                      fontWeight: 'bold',
                    }}
                  >
                    <span class="info">
                      {(
                        service?.metrics?.[selectedRangeType]?.numberOfPods || 0
                      ).toFixed(0)}
                    </span>
                  </div>
                </div>
                {podsMessage ? (
                  <>
                    <div class="body">
                      <div class="title">{podsMessage}</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
